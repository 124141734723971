<template>
  <base-mobile-page>
    <template v-slot:main>
        <el-main style="padding:40px 0px;margin:0 auto;width:100%;max-width:1300px;">
          <el-breadcrumb v-if="browserFrom != 'app'" separator-class="el-icon-arrow-right" style="padding:0px 20px;">
            <el-breadcrumb-item to="/">Home</el-breadcrumb-item>
            <el-breadcrumb-item>hotels</el-breadcrumb-item>
          </el-breadcrumb>
          <div style="margin-top:-40px;" v-else></div>
          <div class="product-list-row" >
              <div class="product-item-col" v-for="(item, index) in hotels" :key="index" :item="item">
                <hotel-item :item="item"></hotel-item>
                <el-divider class="my-divider"></el-divider>
              </div>
          </div>
          <h1>Hotel Booking Instruction</h1>
          <div style="text-align:left; margin:20px;">1. UClub member needs to call or email the hotels directly to make a booking.</div>
          <div style="text-align:left; margin:20px;">2.	Before check-in, Uclub member needs to present the UClub membership card to the front desk.</div>
          <div style="text-align:left; margin:20px;">3.	Please scan the UClub QR code placed at the front desk for check-in confirmation. UClub points will be rewarded after check-out.</div>
          <div style="text-align:center;">
              <div style="font-size:14px;font-weight:bold;text-align:center;">UClub QR Example</div>
            <!--<el-image style="width:250px;" :src="require('../../assets/scan_example.png')"></el-image>-->
          </div>
      </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import HotelItem from '../../components/widgets/HotelItem.vue'
import ModelHotel from '../viewmodel/ModelHotel.vue'
import {mapState} from 'vuex'

export default {
    extends: ModelHotel,
    name: 'hotels',
    components: {
        BaseMobilePage,
        HotelItem
    },
    data(){
      return {
        from: ''
      }
    },
    computed: {
      ...mapState('client', ['browserFrom'])
    }
}
</script>

<style scoped>
.product-list-row{
    display: block;
    padding: 20px;
}
.product-item-col{
    flex: 1 0 49%;
}
</style>